import addImageIcon from "../../images/add_image.png";
import classes from "./Input.module.css";
import React, { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, arrayUnion, updateDoc } from "firebase/firestore";
import { firestore, storage } from "../../services/firebase.config";
import imageCompression from "browser-image-compression";

export const AddImageButton = (props) => {
  const item = props.item;
  const [uploading, setUploading] = useState(false);

  /// 前提条件: 画像をWEBからアップロードできるのはプレミアムユーザー限定
  const handleFileChange = async (event) => {
    const files = event.target.files;

    if (!files || files.length === 0) {
      alert("ファイルが選択されていません！");
      return;
    }

    if (files.length > 30) {
      alert(`一度にアップロードできるのは最大${30}枚までです！`);
      return;
    }

    setUploading(true);

    try {
      // Firestorageにアップロード
      const uploadTasks = Array.from(files).map(async (file) => {
        const fileId = crypto.randomUUID();
        const storageRef = ref(storage, `rooms/${item.id}/${fileId}.png`);
        // 圧縮
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 2160,
        };
        const compressedFile = await imageCompression(file, options);
        await uploadBytes(storageRef, compressedFile);

        return await getDownloadURL(storageRef);
      });

      // アップロードした画像のURLを取得
      const uploadedUrls = await Promise.all(uploadTasks);

      // FirestoreにURLを書き込む
      const roomRef = doc(firestore, "rooms", item.id);
      await updateDoc(roomRef, { image_urls: arrayUnion(...uploadedUrls) });
    } catch (error) {
      console.error("アップロードに失敗しました:", error);
      alert("アップロード中にエラーが発生しました。");
    } finally {
      setUploading(false);
    }
  };

  // 検証しやすいように条件を判定させているため後でなおす
  return item.enabled_two_way_sync ? (
    <div className={`${classes.addImageItemBox}`}>
      <label>
        <div
          className={
            uploading
              ? `${classes.addImageItemUploading}`
              : `${classes.addImageItem}`
          }
        >
          <img src={addImageIcon} alt="写真・動画追加" class=""></img>
        </div>
        <input
          type="file"
          accept="image/jpeg, image/png"
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }}
          disabled={uploading}
        />
        {uploading && (
          <div className={`${classes.spinIndicatorBox}`}>
            <div className={`${classes.spinIndicator}`} />
          </div>
        )}
      </label>
    </div>
  ) : (
    <></>
  );
};
