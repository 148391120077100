import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";

import Home from "./Components/Home/Home";
import SignUp from "./Components/SignUp";
import NotFound from "./Components/NotFound";

import { firestore } from "./services/firebase.config";
import { query, where, or, collection, onSnapshot } from "firebase/firestore";

export const App = () => {
  const [roomID, setRoomID] = useState();
  const [item, setItem] = useState(null);

  useEffect(() => {
    const roomID = crypto.randomUUID();
    setRoomID(roomID);
    onSnapshot(
      query(
        collection(firestore, "rooms"),
        or(where("id", "==", roomID), where("booking_id", "==", roomID))
      ),
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "removed") {
            window.location.reload();
          }
          setItem({
            id: change.doc.id,
            text: change.doc.data().text,
            image_urls: change.doc.data().image_urls.reverse(),
            video_urls: change.doc.data().video_urls.reverse(),
            last_reseted_time: change.doc.data().last_reseted_time.toDate(),
            access_status: change.doc.data().access_status,
            used_storage: change.doc.data().used_storage,
            user_id: change.doc.data().user_id,
            createdAt: change.doc.data().createdAt,
            updatedAt: change.doc.data().updatedAt,
            enabled_two_way_sync: change.doc.data().enabled_two_way_sync,
          });
        });
      }
    );

    const fetchTimeAndCheckReload = async () => {
      if (item != null) {
        const firestoreTime = item.last_reseted_time;
        const currentTime = new Date();
        const thirtyMinutesInMilliseconds = 30 * 60 * 1000;

        if (currentTime - firestoreTime >= thirtyMinutesInMilliseconds) {
          onSnapshot(
            query(
              collection(firestore, "users"),
              where("id", "==", item.user_id)
            ),
            (snapshot) => {
              // FIXME: Userが存在しないことはあり得ないが参照に失敗した場合クラッシュするのでいい感じにしたい
              const user = snapshot.docs[0].data();
              // NOTE: プレミアム会員ではない場合のみリロードさせる
              if (user.type !== "premium") {
                window.alert(
                  "データ削減のため10秒後にリロードされます。\n（プレミアムプランに加入することでリロード機能をオフにすることができます）"
                );
                setTimeout(() => {
                  window.location.reload();
                }, 10000);
              }
            }
          );
        }
      }
    };

    const interval = setInterval(() => {
      fetchTimeAndCheckReload();
    }, 10000);

    return () => clearInterval(interval);
  }, [item]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home roomID={roomID} item={item} />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
